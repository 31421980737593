import React, { ReactElement } from 'react';

import { ReactComponent as GlobalIcon } from '../../statics/icons/global-filled.svg';
import { ReactComponent as MailIcon } from '../../statics/icons/mail-filled.svg';
import { ReactComponent as PhoneIcon } from '../../statics/icons/phone-circle-filled.svg';
import { IUSer } from '../../types/user';

const USER_DATA: IUSer = {
  name: 'Nguyen Thanh Hung',
  title: 'Director',
  address: '186 Dang Van Ngu Phu Nhuan HCM 70000',
  company: 'Faba Technology',
  companyWebsite: 'https://fabatechnology.com',
  phoneNumber: '+84 984-022-228',
  email: 'harryslupus@gmail.com',
  backgroundImage: '/images/user-background.png',
  avatar: '/images/avatar.png',
};

const SOCIAL_INFORMATION: Record<string, { title: string; icon: ReactElement; href: string; target?: string }> = {
  phoneNumber: {
    title: 'Phone Number',
    icon: <PhoneIcon />,
    href: 'tel:',
  },
  email: {
    title: 'Email',
    icon: <MailIcon />,
    href: 'mailto:',
  },
  companyWebsite: {
    title: 'Website',
    icon: <GlobalIcon />,
    href: '',
    target: '_blank',
  },
};

export { USER_DATA, SOCIAL_INFORMATION };

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './container/home';
import NotFound from './container/not-found';

function App() {
  return (
    <BrowserRouter>
      <div className="min-h-screen bg-grey-100">
        <Routes>
          <Route path="/:userName" element={<Home />} />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;

import React from 'react';

const NotFound = () => {
  return (
    <div className="flex items-center h-full p-16 dark:bg-gray-900 dark:text-gray-100">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="max-w-md text-center">
          <h2 className="mb-8 font-extrabold text-9xl text-primary-500">
            <span className="sr-only">Error</span>404
          </h2>
          <p className="text-primary-300 font-semibold md:text-3xl">Sorry, we could not find this page.</p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;

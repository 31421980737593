import { IUSer } from './../types/user';
import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';
import rearg from 'lodash/rearg';

/**
 * Convert all snake case of key in object to camel case
 */
const toCamelCase = (obj: Record<string, any>): Record<string, any> => mapKeys(obj, rearg(camelCase, 1));

/**
 * Create the information on VCard
 */
const generateVCard = ({ name, title, email, phoneNumber, address, company, companyWebsite }: IUSer): Blob => {
  return new Blob(
    [
      `BEGIN:VCARD
VERSION:3.0
N:${name};;;
FN:${name}
TITLE:${title}
ORG:${company}
URL;type=pref:${companyWebsite}
EMAIL;type=Email:${email}
TEL;type=CELL;type=VOICE;type=pref:${phoneNumber}
ADR;type=WORK;type=pref:;;;${address};;;
END:VCARD
`,
    ],
    { type: 'text/vcard;charset=utf-8' },
  );
};

export { toCamelCase, generateVCard };

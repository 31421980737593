import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as SaveIcon } from '../../statics/icons/save-filled.svg';
import { ReactComponent as BriefCaseIcon } from '../../statics/icons/briefcase.svg';
import { ReactComponent as MapPinIcon } from '../../statics/icons/map-pin.svg';
import { ReactComponent as Logo } from '../../statics/icons/logo.svg';
import { SOCIAL_INFORMATION, USER_DATA } from '../../statics/data';

import { useNavigate, useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';

import { db } from '../../firebase';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { IUSer } from '../../types/user';
import { generateVCard, toCamelCase } from '../../helper';

const Home: FC = () => {
  const [userInfo, setUserInfo] = useState<IUSer | null>(null);

  const { userName } = useParams();
  const navigate = useNavigate();

  const { name, title, backgroundImage, company, avatar, address } = userInfo || {}; // Todo this is using mock data

  useEffect(() => {
    if (userName) {
      const q = query(collection(db, 'users'), where('user_name', '==', userName));
      onSnapshot(q, querySnapshot => {
        const usersData = querySnapshot.docs.map(doc => doc.data());
        if (usersData.length) {
          setUserInfo(toCamelCase(usersData[0]) as IUSer);
        } else {
          navigate('/not-found');
        }
      });
    }
  }, [navigate, userName]);

  const downloadContact = () => {
    if (userInfo) {
      saveAs(generateVCard(userInfo), `${name}.vcf`);
    }
  };

  return (
    <div className="md:pt-4 md:h-screen">
      {userInfo && (
        <div className="flex flex-col overflow-hidden bg-white shadow min-h-screen md:min-h-fit md:rounded-lg sm:mx-auto md:max-w-screen-sm relative">
          <div className="absolute top-0 w-full">
            <img
              className="h-52 w-full object-cover"
              src={backgroundImage || USER_DATA.backgroundImage}
              alt={company}
            />
          </div>
          <div className="border-gray-200 mt-36 bg-white mr-2 ml-4">
            <div className="flex justify-between items-center">
              <div className="z-10 relative">
                <div className="rounded-100 border-solid border-2 border-white overflow-hidden z-20">
                  <img className="w-120 h-120 object-cover" src={avatar || USER_DATA.avatar} alt={name} />
                </div>
                <div className="absolute w-8 h-8 right-1.5 bottom-1.5 overflow-hidden rounded-100 shadow-medium">
                  <Logo className="absolute -bottom-5 -left-2.5" />
                </div>
              </div>
              <button className="flex items-center z-10 px-4 py-2 bg-primary-500 rounded-lg" onClick={downloadContact}>
                <SaveIcon className="mr-2" />
                <span className="text-white">Save contact</span>
              </button>
            </div>
            <div className="mt-1">
              <h1 className="text-primary-500 font-bold text-xl">{name}</h1>
              <span className="text-primary-300">{title}</span>
            </div>
            <div className="mt-3 mb-6">
              {company && (
                <div className="flex items-center mb-2">
                  <BriefCaseIcon className="mr-2" />
                  <span className="font-bold text-primary-500">{company}</span>
                </div>
              )}
              {address && (
                <div className="flex items-center">
                  <MapPinIcon className="mr-2" />
                  <span className="text-primary-500">{address}</span>
                </div>
              )}
            </div>
          </div>
          <div className="bg-grey-200 sm:flex-auto md:flex-1">
            <div className="grid gap-3 p-4 pb-10">
              {userInfo &&
                Object.keys(SOCIAL_INFORMATION).map(key => {
                  const { href, icon, title, ...restParams } = SOCIAL_INFORMATION[key];

                  return (
                    <a
                      {...restParams}
                      href={`${href}${userInfo[key as keyof object]}`}
                      className="flex items-center bg-white p-3 rounded-lg h-max"
                      key={key}
                    >
                      {icon}
                      <div className="flex flex-col ml-3">
                        <span className="text-primary-500 font-bold">{userInfo[key as keyof object]}</span>
                        <span className="text-primary-300 text-sm">{title}</span>
                      </div>
                    </a>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
